import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import RollbarComponent from '../../components/shared/rollbar.component'
import { isPropValidForForwarding, personalizedTheme } from '../../theme'
import { I18nService } from './i18n.service'

const i18nScoped = new I18nService(require('./modals.service.yml'))

export interface ModalControls<T> {
  onResult: (result: T) => void
  onCancel: () => void
  onDismiss?: () => void
}

interface ModalDecorationOptions {
  blockerClass?: string
  blockerStyle?: React.CSSProperties
  outerModalStyle?: React.CSSProperties
  outerModalClass?: string
  helpUrl?: string
}

type ModalContentGenerator<T> = (controls: ModalControls<T>) => JSX.Element

export function modalOpenConfirm (title: React.ReactNode, message?: React.ReactNode) {
  return modalOpenDecorated<boolean>(title, controls => <div className='modal-inner' >
    { message && <div style={{ whiteSpace: 'pre-wrap' }} className="u-mbm u-pbm u-borderB">{ message }</div> }

    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <button
        id="js-test-modal-cancel-button"
        className='buttonNeutral'
        onClick={ () => controls.onResult(false) }
        children={ i18nScoped.t('cancel') } />

      <button
        id="js-test-modal-confirm-button"
        className='button'
        autoFocus
        onClick={ () => controls.onResult(true) }
        children={ i18nScoped.t('confirm') } />
    </div>
  </div>)
}

export function modalOpenAlert (title: React.ReactNode, message?: React.ReactNode) {
  return modalOpenDecorated<boolean>(title, controls => <div className='modal-inner' >
    { message && <div style={{ whiteSpace: 'pre-wrap' }} className="u-mbm u-pbm u-borderB">{ message }</div> }
  
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <button
          id="js-test-modal-confirm-button"
          className='button'
          autoFocus
          onClick={ () => controls.onResult(true) }
          children={ i18nScoped.t('i_have_understand') } />
    </div>
  </div>)
}

/**
 * Open a new modal from a component with the following props :
 * - onResolve(result: T) => void
 * - onCancel() => void
 */
export function modalOpenDecorated<T> (title: React.ReactNode, contentGenerator: ModalContentGenerator<T>, options: ModalDecorationOptions & { portalAnchor?: HTMLElement } = {}) {
  return modalOpen<T>(
    controls => <ModalDecoration title={ title } contentGenerator={ contentGenerator } options={ options } controls={ controls } />,
    { portalAnchor: options.portalAnchor }
  )
}

export function modalOpen<T> (contentGenerator: ModalContentGenerator<T>, options: { portalAnchor?: HTMLElement } = {}) {
  const modalContainer = document.createElement('div');
  (options.portalAnchor || document.body).appendChild(modalContainer)

  return new Promise<T>(resolve => {
    const onResult = (result: T) => { setTimeout(modalCleanup.bind(null, modalContainer), 1); resolve(result) }
    const onCancel = () => { setTimeout(modalCleanup.bind(null, modalContainer), 1); resolve(null) }
    const onDismiss = () => setTimeout(modalCleanup.bind(null, modalContainer), 1)
    const content = contentGenerator({ onResult, onCancel, onDismiss })

    const modal = <RollbarComponent>
      <ThemeProvider theme={ personalizedTheme(window.marketColors) }>
        <StyleSheetManager shouldForwardProp={ isPropValidForForwarding }>
          { content }
        </StyleSheetManager>
      </ThemeProvider>
    </RollbarComponent>

    ReactDOM.render(modal, modalContainer)
  })
}

interface ModalDecorationProps<T>  { 
  controls: ModalControls<T>
  options?: ModalDecorationOptions
  title: React.ReactNode
  children?: React.ReactNode
  contentGenerator?: ModalContentGenerator<T>
}

export function ModalDecoration<T> ({ controls, options = {}, title, children, contentGenerator }: ModalDecorationProps<T>) {
  return <div className={ `jquery-modal blocker ${options.blockerClass || ''}` } style={ options.blockerStyle }>
    <div className={ `modal u-borderRadius0375 modal-container ${ options.outerModalClass || '' }` } style={ options.outerModalStyle }>
      <div className="modal-header" style={ options.helpUrl ? { paddingRight: '5.625rem' } : {} }>
        { title && <h3 className="modal-header-title">{ title }</h3> }

        { options.helpUrl && <div className="u-positionAbsolute u-gridInline-vMiddle" style={{ height: '3.4375rem', top: 0, right: '3.4375rem' }}>
          <a href={ options.helpUrl } target="_blank" rel="noopener noreferrer" className="icon-question" style={{ transform: 'scale(1.5)' }} />
        </div> } 
        <button onClick={ controls.onCancel } className="buttonClose"></button>
      </div>

      { children || contentGenerator(controls) }
    </div>
  </div>
}

function modalCleanup (modalContainer: HTMLDivElement) {
  ReactDOM.unmountComponentAtNode(modalContainer)
  modalContainer.remove()
}

import * as React from 'react'
import { I18nService } from '../../services/utils/i18n.service'
import { httpGetRoute } from '../../services/utils/http.service'
import { userDepersonate } from '../../services/models/users.service'
import AvatarComponent from './avatar.component'
import { debounce } from '../../services/utils/lodash.service'

interface Props { menu: Menu }
interface MenuItem { href: string, icon: string, text: string, active: boolean, className?: string, count?: number }
interface MenuItemCollection { items: MenuItem[], accountItems: MenuItem[] }

const i18nScoped = new I18nService(require('./menu.component.yml'))
const LazyClockingButtonComponent = React.lazy(() => import('../employees/clockings/clocking-button.component'))

export default function MenuComponent ({ menu, menu: { logo, baseline, employee, customer, backoffice, messaging } }: Props) {
  const shouldUseCompactLayout = React.useCallback(() => {
    return window.innerWidth < 1200 || location.href.includes('join_meeting') || window.currentUser?.type === 'Operator'
  }, [])

  const [opened, setOpened] = React.useState(false)
  const [onSmallDevice, setOnSmallDevice] = React.useState(shouldUseCompactLayout())
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight)

  const menuRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const resizeListener = debounce(() => {
      setOnSmallDevice(shouldUseCompactLayout())
      setInnerHeight(window.innerHeight)
    }, 50)

    window.addEventListener('resize', resizeListener)

    // Put the generated HTML in cache. It will be shown on next navigation until react got initialized.
    localStorage.setItem('cacheMenu', menuRef.current.innerHTML)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  })

  const renderedSideMenuWebsites = React.useMemo(() => {
    const menuItems: MenuItem[] = []
    if (menu.websiteUrl) menuItems.push({ href: menu.websiteUrl, icon: 'globe', text: 'website', active: false })
    return menuItems
  }, [menu.websiteUrl])

  const getMenuItemCollectionForCustomer  = React.useCallback(() => {
    const { customer, messaging } = menu
    const subpath = window.location.href.split(httpGetRoute()).pop()
    const accountItems: MenuItem[] = [
      { href: 'edit', icon: 'edit', text: 'profile', active: ['/edit', '/phone_numbers', '/addresses'].some(_ => subpath.startsWith(_)) },
      { href: 'payment_facilities', icon: 'coins', text: 'payment_facilities', active: subpath.startsWith('/payment_facilities') },
      { href: 'password', icon: 'lock', text: 'password', active: subpath.startsWith('/password') },
      customer.urssafPayment && { href: 'urssaf_registration_payload', icon: 'list-check', text: 'urssaf_registration_payload', active: subpath.startsWith('/urssaf_registration_payload') },
      customer.gdpr && { href: 'gdpr_campaigns', icon: 'at', text: 'gdpr_campaigns', active: subpath.startsWith('/gdpr_campaigns') },
      messaging?.active && { href: 'delete', icon: 'remove', text:'delete', active: subpath.startsWith('/delete'), className: 'u-bgClrError' },
    ].filter(_ => _)

    const items: MenuItem[] = [
      { href: '', icon: 'home', text: 'dashboard', active: subpath === '' || subpath === '/'  },
      { href: 'castings/current', icon: 'question', text: 'castings', active: subpath.startsWith('/castings') },
      { href: 'events/incoming', icon: 'calendar', text: 'events', active: subpath.startsWith('/events') },
      customer.ratings && { href: 'ratings', icon: 'rating', text: 'ratings_customer', active: subpath.startsWith('/ratings') },
      { href: 'employees', icon: 'users', text: 'employees', active: subpath.startsWith('/employees') },
      window.market_type === 'Marketplace' && { href: 'suppliers', icon: 'building', text: 'suppliers', active: subpath.startsWith('/suppliers') },
      customer.couponsPack && { href: 'bought_coupons_packs', icon: 'box', text: 'coupons_packs', active: subpath.startsWith('/bought_coupons_packs') },
      messaging?.active && { href: 'messaging_conversations', icon: 'messaging', text: 'messaging', active: subpath === '/messaging_conversations' },
      customer.followUpLiaisonBook?.active && { href: customer.followUpLiaisonBook.url.replace('/account/', ''), icon: 'address-book', text: 'customer_follow_up_liaison_book', active: window.location.pathname.startsWith(customer.followUpLiaisonBook.url) },
      { href: 'documents', icon: 'file', text: 'documents', active: subpath.startsWith('/documents') },
      customer.directory && { href: 'directory', icon: 'address-book', text: 'directory', active: subpath.startsWith('/directory'), className: 'menu-item js-directory-menu-link'  },
      ...renderedSideMenuWebsites
    ].filter(_ => _)

    return { items, accountItems }
  }, [menu, renderedSideMenuWebsites])

  const getMenuItemCollectionForEmployee = React.useCallback(() => {
    const { employee, currency } = menu
    const subpath = window.location.href.split(httpGetRoute()).pop()
    const accountItems: MenuItem[] = [
      !employee.candidateWithLimitedAccess && { href: 'sumup', icon: 'check', text: 'sumup', active: subpath.startsWith('/sumup') },
      { href: 'principal_information', icon: 'edit', text: 'principal_information', active: subpath.startsWith('/principal_information') },
      employee.profileDocument && { href: 'profile_document', icon: 'pdf', text: 'profile_document', active: subpath.startsWith('/profile_document') },
      { href: 'intervention_sector', icon: 'pointer', text: 'intervention_sector', active: subpath.startsWith('/intervention_sector') },
      { href: 'planning', icon: 'calendar', text: 'planning', active: subpath.startsWith('/planning') },
      { href: 'skills', icon: 'graduation-cap', text: 'skills', active: subpath.startsWith('/skills') },
      employee.configureEmployeeServices && { href: 'employee_services', icon: 'graduation-cap', text: 'employee_services', active: subpath.startsWith('/employee_services') },
      employee.bankDetails && { href: 'bank_details', icon: 'university', text: 'bank_details', active: subpath.startsWith('/bank_details') },
      employee.urssafPayment && { href: 'urssaf_payment', icon: 'list-check', text: 'urssaf_payment', active: subpath.startsWith('/urssaf_payment') },
      employee.novaExport && { href: 'nova_export_config', icon: 'file-export', text: 'nova_export_config', active: subpath.startsWith('/nova_export_config') },
      employee.candidateWithLimitedAccess && { href: 'sumup', icon: 'check', text: 'confirm_postulation', active: subpath.startsWith('/sumup') },
      { href: 'password', icon: 'lock', text: 'password', active: subpath.startsWith('/password') },
      employee.gdpr && { href: 'gdpr_campaigns', icon: 'at', text: 'gdpr_campaigns', active: subpath.startsWith('/gdpr_campaigns') }
    ].filter(_ => _)

    if (employee.candidateWithLimitedAccess) return { items: accountItems, accountItems: [] } as MenuItemCollection
    
    const items: MenuItem[] = [
      { href: '', icon: 'home', text: 'dashboard', active: subpath === '' || subpath === '/'  },
      { href: 'castings/current', icon: 'bullhorn', text: 'castings_employee', active: subpath.startsWith('/castings') },
      { href: 'events/incoming', icon: 'calendar', text: 'events', active: subpath.startsWith('/events') },
      employee.appointment && { href: 'appointments', icon: 'calendar-clock-sharp-regular', text: 'appointments', active: subpath.startsWith('/appointments') || subpath.startsWith('/appointment_proposals') },
      employee.quotation && { href: 'quotations', icon: 'sign', text: 'quotations', active: subpath.startsWith('/quotations') },
      employee.ratings && { href: 'ratings', icon: 'rating', text: 'ratings', active: subpath.startsWith('/ratings') },
      { href: 'customers', icon: 'users', text: 'customers', active: subpath.startsWith('/customers') },
      employee.clockings && { href: 'clockings', icon: 'clock', text: 'clockings', active: subpath.startsWith('/clockings') },
      { href: 'documents', icon: 'file', text: 'documents', active: subpath.startsWith('/documents') },
      (employee.employeeType === 'independant' || employee.employeeType === 'subcontractor') && { href: 'invoices', icon: 'file-invoice', text: 'invoices', active: subpath.includes('invoices') },
      employee.novaExport && { href: 'nova_export_payloads', icon: 'file-export', text: 'nova_export_config', active: subpath.includes('nova_export_payloads') },
      employee.paymentTransactions && { href: 'payment_transactions', icon: 'money-bill', text: 'transactions', active: subpath.includes('payment_transactions') },
      employee.employeeType === 'independant' && { href: 'formalities_stats', icon: currency === 'EUR' ? 'euro' : 'dollar', text: 'formalities_stats', active: subpath.includes('formalities_stats') },
      ...renderedSideMenuWebsites,
      { href: i18nScoped.t('external_links.employee_global_help_url'), icon: 'question', text: 'help', active: false }
    ].filter(_ => _)

    return { items, accountItems } as MenuItemCollection
  }, [menu, renderedSideMenuWebsites])

  const getMenuItemCollectionForBackoffice = React.useCallback(() => {
    const { backoffice, backoffice: { whiteLabelMarketplace } } = menu
    const subpath = window.location.href.split(httpGetRoute()).pop()
    const myAccountPath = `/backoffice/operators/${ window.currentUser.id }`

    const accountItems: MenuItem[] = [{ href: myAccountPath + '/edit', icon: 'edit', text: 'profile', active: subpath.startsWith(myAccountPath + '/edit') }]

    const items: MenuItem[] = [
      { href: 'castings', icon: 'bullhorn', text: 'castings', active: subpath.startsWith('/castings') },
      backoffice.quotationModule && { href: 'quotations', icon: 'sign', text: 'quotations', active: subpath.startsWith('/quotations') },
      backoffice.productModule && { href: 'service_deliveries/packages', icon: 'box', text: 'packages', active: subpath.startsWith('/service_deliveries/packages') },
      { href: 'events', icon: 'calendar', text: 'events_operator', active: subpath.startsWith('/events') },
      backoffice.ratingModule && { href: 'ratings', icon: 'rating', text: 'ratings_operator', active: subpath.startsWith('/ratings') },
      { href: 'customers', icon: 'users', text: 'customers', active: subpath.startsWith('/customers') },
      !backoffice.soloEdition && { href: 'employees', icon: 'users', text: 'employees', active: subpath.startsWith('/employees') },
      backoffice.clockingModule && { href: 'clockings', icon: 'clock', text: 'clockings', active: subpath.startsWith('/clockings') },
      { href: 'documents', icon: 'file', text: 'documents', active: subpath.startsWith('/documents') },
      backoffice.invoicingModule && { href: 'invoices', icon: 'file-invoice', text: 'invoicing', active: subpath.startsWith('/invoices') },
      backoffice.selfInvoicing && { href: 'provider_invoices', icon: 'file-invoice', text: 'provider_invoices', active: subpath.startsWith('/provider_invoices') },
      backoffice.selfInvoicing && menu.currency === 'EUR' && { href: 'grouped_bank_transfers', icon: 'envelopes-bulk', text: 'grouped_bank_transfers', active: subpath.startsWith('/grouped_bank_transfers') },
      backoffice.invoicingModule && { href: 'payment_transactions', icon: 'money-bill', text: 'transactions', active: subpath.includes('payment_transactions') },
      backoffice.invoicingModule && backoffice.novaExportModule && { href: 'nova_export_payloads', icon: 'file-export', text: 'nova_export_config', active: subpath.includes('nova_export_payloads') },
      backoffice.invoicingModule && backoffice.cdcDac7Exports && { href: 'cdc_dac7_exports', icon: 'file-export', text: 'cdc_dac7_exports', active: subpath.includes('cdc_dac7_exports') },
      backoffice.adminSupplier && { href: 'offered_services', icon: 'gear', text: 'offered_services_and_params', active: subpath.startsWith('/offered_services') || subpath.startsWith('/suppliers') },
      backoffice.adminSupplier && { href: 'operators', icon: 'users', text: 'operators', active: subpath.startsWith('/operators') },
      backoffice.adminSupplier && (backoffice.ogustineConnectEnabled || backoffice.haveActiveStandardMarketplaceMemberships) && !whiteLabelMarketplace && { href: 'memberships', icon: 'building', text: 'marketplaces', active: subpath.startsWith('/memberships') || subpath.startsWith('/marketplaces'), count: backoffice.ogustineConnectNewMarketplaceCount },
      backoffice.adminSupplier && whiteLabelMarketplace && { href: `marketplaces/${whiteLabelMarketplace.id}`, icon: 'building', text: whiteLabelMarketplace?.name, active: subpath.startsWith('/memberships') || subpath.startsWith('/marketplaces') },
      { href: 'outbound_notifications', icon: 'send', text: 'outbound_notifications', active: subpath.startsWith('/outbound_notifications') },
      backoffice.stripeTerminalPayment && window.ogustineBridge?.startDiscoveringStripeTerminalReaders && { href: 'stripe_terminals', icon: 'mobile-signal-out-regular', text: 'stripe_terminals', active: subpath.startsWith('/stripe_terminals') },
      { href: i18nScoped.t('external_links.whats_new_url'), icon: 'rss', text: 'whats_new', active: false },
      { href: i18nScoped.t('external_links.backoffice_global_help_url'), icon: 'question', text: 'help', active: false }
    ].filter(_ => _)

    return { items, accountItems } as MenuItemCollection
  }, [menu])

  const menuItemCollection = React.useMemo(() => {
    if (window.currentUser.type === 'Employee') return getMenuItemCollectionForEmployee()
    if (window.currentUser.type === 'Customer') return getMenuItemCollectionForCustomer()
    if (window.currentUser.type === 'Operator' && window.market_type === 'Supplier') return getMenuItemCollectionForBackoffice()
    return null
  }, [getMenuItemCollectionForBackoffice, getMenuItemCollectionForCustomer, getMenuItemCollectionForEmployee])

  const [deployAccountSubMenu, setDeployAccountSubMenu] = React.useState(menuItemCollection?.accountItems.some(_ => _.active))


  const homePath = React.useMemo(() => {
    return menu.employee?.candidateWithLimitedAccess ? `/${httpGetRoute()}/principal_information` : `/${httpGetRoute()}`
  }, [menu.employee?.candidateWithLimitedAccess])

  const renderedLogoutOrAdminLink = React.useMemo(() => {
    if (!menu.isUsurping) return <a className="logout-entry menu-item" href="/users/sign_out"><em className="icon-sign-out"></em>{ i18nScoped.t('logout') }</a>
    return <a className="logout-entry menu-item" onClick={ userDepersonate }><em className="icon-sign-out"></em>{ i18nScoped.t('back_to_admin') }</a>
  }, [menu.isUsurping])

  const renderedSideMenu = React.useMemo(() => {
    if (!menuItemCollection) return null
    
    const items = menuItemCollection.items.slice()
    const accountItems = menuItemCollection.accountItems.slice()
    const effectiveOpened = !onSmallDevice || opened

    // if currently in the profile, deploy the corresponding sub-menu at the beginning of the menu
    if (deployAccountSubMenu) items.unshift(...accountItems.map(_ => ({ ..._, className: `profile-entry ${_.className || ''}` })))

    const onAccountClicked = () => {
      if (accountItems.length === 1) window.location.href = accountItems[0].href
      else setDeployAccountSubMenu(val => !val)
    }

    return <div>
      { effectiveOpened && onSmallDevice && <div className="side-menu-blocker" onClick={ () => setOpened(false) }></div> }
      
      <div className={ 'side-menu' + (effectiveOpened ? ' side-menu-open' : '') } style={{ height: innerHeight, maxHeight: innerHeight }}>
        { !onSmallDevice && <style>{ `
          .containerPage, .main, .content, .headerMain, .footer, .msgNotif, #js-casting-container, .service-delivery-meeting-feature-container { margin-left: 20em }
          .castingHeader.is-fixed { width: calc(100% - 20em) !important; }
        ` }</style> }

        { onSmallDevice && effectiveOpened && <style>{ `
          .service-delivery-meeting-feature-container {
            bottom: -200px !important;
          }
        ` }</style> }

        { effectiveOpened && <div className="side-menu-content">
          { onSmallDevice && <div className="side-menu-logo u-pts u-pbs">
            <em className="icon-prev u-mls txtM u-mbn" onClick={ () => setOpened(false) }></em>
            <div className="u-txtCenter u-prl"><a href={ homePath }><img src={ menu.logo.url } alt={ menu.logo.alt } /></a></div>
          </div> }

          <div className="profile-entry" onClick={ onAccountClicked } style={{ cursor: accountItems.length ? 'pointer' : '' }}>
            <div><AvatarComponent src={ window.currentUser.photo.url } initials={ window.currentUser.initial } className="avatar--M u-mrs" /></div>
            <div style={{ flexGrow: 1 }}><div className="u-txtBold">{ window.currentUser.full_name }</div> { i18nScoped.t('edit_profile')}</div>
            { accountItems.length > 0 && <em className="icon-next" style={{ width: 'unset' }}></em> }
          </div>

          { items.map((item, index) => <a
            key={ index }
            className={ `${item.className || 'menu-item'} ${ item.active ? 'active' : '' }` }
            href={ item.href.startsWith('http') ? item.href : `/${httpGetRoute()}/${item.href}` }
            target={ item.href.startsWith('http') ? '_blank' : undefined } rel="noreferrer">
            <em className={ `icon-${item.icon}` }></em>{ i18nScoped.t(item.text, {}, item.text) }
            { !!item.count && <span className="u-positionRelative menu-icon-count" style={{ top: '-0.5em', fontSize: '0.9rem', fontFamily: 'ogustine-icons' }}>{ item.count }</span> }
          </a>) }
        </div> }

        { effectiveOpened && renderedLogoutOrAdminLink }
      </div>
    </div>

  }, [deployAccountSubMenu, homePath, innerHeight, menu.logo.alt, menu.logo.url, menuItemCollection, onSmallDevice, opened, renderedLogoutOrAdminLink])


  return <div ref={ menuRef }>
    { renderedSideMenu }

    <header className="headerMain" role="banner" id="header">
      <div className="containerPage-inner" style={{ display: 'flex', alignItems: 'center' }}>
        { /** hamburger menu icon on the left*/ }
        { onSmallDevice && <a className="menu-icon icon-menu" onClick={ () => setOpened(!opened) }></a>  }

        { /** the market logo, visible on large screens */ }
        <a href={ homePath } className="hidden-xs"><img className="u-mls" src={ logo.url } alt={ logo.alt } /></a>
        { window.currentUser.type === 'Employee' && <a className="u-mrs visible-xs icon-home menu-icon" href={ homePath }></a> }

        { /** Operators have an additional menu on top of the desktop screen while users have the baseline instead */ }
        { window.currentUser.type !== 'Operator' && <p className="headerMain-baseline hidden-xs">{ baseline }</p> }
        
        { /** clocking (for employee) or new casting (customer) button. This button is centered on phones and small tablettes */ }
        <span style={{ flexGrow: 1 }}></span>
        { employee.clockings && !employee.candidateWithLimitedAccess && <React.Suspense fallback={ null }><LazyClockingButtonComponent /></React.Suspense> }
        
        { (customer.newCasting || employee.newCasting) && <a
          className="u-mrm u-mlm button button--S"
          href={ `/${window.market_type.toLocaleLowerCase()}s/${window.market_id}/castings/new` }
          children={ i18nScoped.t('new_casting') } /> }

        { window.currentUser.type === 'Operator' && window.market_type === 'Supplier' && <div className="headerMain-middle">
          <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/castings` }>{ i18nScoped.t('castings') }</a>

          <a className="headerMain-middle-link" style={{ position: 'relative' }} href={ `/${httpGetRoute()}/plannings` }>
            { i18nScoped.t('events_operator') }
            { backoffice.anyIncomingEventToUpdate && <span className="menu-icon-count" style={{ position: 'static', height: '1.3em', marginLeft: '0.4em' }} /> }
          </a>

          <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/customers` }>{ i18nScoped.t('customers') }</a>
          
          { backoffice.soloEdition
            ? <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/invoices` }>{ i18nScoped.t('invoicing') }</a>
            : <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/employees` }>{ i18nScoped.t('employees') }</a> }

          { backoffice.adminSupplier && <a
            className="headerMain-middle-link"
            href={ `/${httpGetRoute()}/offered_services` }
            children={ i18nScoped.t('offered_services_and_params') } /> }
        </div> }
        <span className="visible-xs-block" style={{ flexGrow: 1 }}></span>

        { /** icons on the right, including customerFollowUpLiaisonBook (for customer), messaging and cart (for customer) */ }
        { customer.followUpLiaisonBook?.active && <a className="u-mrs icon-address-book menu-icon" href={ customer.followUpLiaisonBook.url }>
          { !!customer.followUpLiaisonBook.count && <span className="menu-icon-count">{ customer.followUpLiaisonBook.count }</span> }
        </a> }

        { messaging?.active && <a className="u-mrs icon-messaging menu-icon" href={ `/${httpGetRoute()}/messaging_conversations` }>
          { !!messaging.count && <span className="menu-icon-count">{ messaging.count }</span> }
        </a> }

        { customer.cart?.active && <a className="u-mrs icon-shopping-cart menu-icon" href={ customer.cart.url }>
          { !!customer.cart.count && <span className="menu-icon-count">{ customer.cart.count }</span> }
        </a> }
      </div>
    </header>
  </div>
}
